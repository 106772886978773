import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "categories" ]

  connect() {
    let _this = this;
    const chipElements = this.element.querySelectorAll('.categories .mdc-chip');
  
    chipElements.forEach((chipEl) => {   
      const chip = new MDCChip(chipEl);   
      chip.listen('MDCChip:interaction', () => {

        if (($("#form_categories").children().length < 5) || chip.selected) {
          console.log($("#form_categories").children().length);
          chip.selected = !chip.selected;
          setTimeout(function () {_this.setCategory($(chipEl).data("category"));}, 200);
        }
      });  
    });
  }

  setCategory(category) {

    $("#form_categories").empty();
    $(".categories .mdc-ripple-upgraded--foreground-activation:not(.mdc-chip--selected), .categories .mdc-chip--selected:not(.mdc-ripple-upgraded--foreground-activation)").each(function( index ) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = "free_form_request["+$( this ).data("fieldname")+"][]";
      input.value = $( this ).data("category");

      $("#form_categories").append(input);
    });

  }


}
