import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lat", "lon", "addr", "country", "city", "address", "destroy"]


  connect () {
    let _this = this;

    if ((typeof google === 'undefined') || (typeof google.maps === 'undefined')) {
      $.getScript("https://maps.googleapis.com/maps/api/js?v=3.0&libraries=places&language=en&key=AIzaSyCj1S5H8dT5MouhTX_ooNUsw80tDAthPHM&callback=dispatchMapsEvent", function() {
        _this.setAddress(_this);
      });
    } else {
      _this.setAddress(_this);
    }
  }

  setAddressInputs(lon, lat, addr, city, country, country_code) {
    this.lonTarget.value = (lon != undefined) ? lon : null;
    this.latTarget.value = (lat != undefined) ? lat : null;
    this.addrTarget.value = (addr != undefined) ? addr : null;
    this.cityTarget.value = (city != undefined) ? city : null;
    this.countryTarget.value = (country != undefined) ? country : null;
    //this.codeTarget.value = country_code;

    let _city = ( this.cityTarget.value != undefined) ? this.cityTarget.value : "";
    let _country = ( this.countryTarget.value != undefined) ? this.countryTarget.value : "";

    if ((_city.length > 0) || (_country.length > 0))
      this.addressTarget.value = [_city, _country].filter(Boolean).join(", ");
    // else
    //   this.addressTarget.value = null

    // console.log("_city:", _city );
    // console.log("_country:", _country );
    $("#gsearch").parent().attr("data-mdc-auto-init-state", null);
    
    window.mdcAutoInit();
  }

  resetAddress(event) {
    if (this.addressTarget.value.length == 0) {
      this.lonTarget.value = null;
      this.latTarget.value = null;
      this.addrTarget.value = null;
      this.cityTarget.value = null;
      this.countryTarget.value = null;
      this.destroyTarget.value = true;
    } else {
      let city = this.cityTarget.value.trim();
      let country = this.countryTarget.value.trim();

      city = (city != undefined) ? city : "";
      country = (country != undefined) ? country : "";


      if ((city.length > 0) || (country.length > 0))
        this.addressTarget.value = [city, country].filter(Boolean).join(", ");
      else
        this.addressTarget.value = null;

      this.destroyTarget.value = null;
    }
  }

  setAddress(l_this) {
    var input = document.getElementById('gsearch');
    var searchBox = new google.maps.places.SearchBox(input);
    var componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_3: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
        };

    searchBox.addListener('places_changed', function() {
      var places = searchBox.getPlaces();

      var lon, lat, addr, city, country, country_code;
      

      if (places.length == 0) {
        return;
      }

      places.forEach(function(place) {
        var street_number = "";
        var route = "";

        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];

          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            
            if (addressType == "country") {
              country = val;
              country_code = place.address_components[i]["short_name"];
            } else if (addressType == "locality") {
              city = val;
            } else if (addressType == "administrative_area_level_3" && city == null) {
              city = val;
            } else if (addressType == "route") {
              route = val;
            } else if (addressType == "street_number") {
              street_number = val;
            } 
          }
          addr = route + " " + street_number;
        }

        lat = place.geometry.location.lat();
        lon = place.geometry.location.lng();
      });
        
      l_this.setAddressInputs(lon, lat, addr, city, country, country_code)
      
    });
  }

}